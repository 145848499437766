import React, { useState, useRef, useEffect } from 'react';
import { Pack, SalePack } from '../../types/packs';
import { IoSend } from 'react-icons/io5';
import ThinkingIndicator from './ThinkingIndicator';
import ReactMarkdown from 'react-markdown';
import { generateRandomString } from '../../utils';
import { useWallet } from '@solana/wallet-adapter-react';
import { useChain } from '../../../../contexts/chainsContext';
import useNeoChain from '../../../../hooks/useNeoChain';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useAppContext } from '../../../../contexts/appContext';
import { useUA } from '../../../../contexts/userTracking';
import toast from 'react-hot-toast';
import SignInButton from '../../../SignInButton';
import Firebase from '../../../../services/firebase';
import { collection, query, orderBy, onSnapshot, Timestamp } from 'firebase/firestore';

export interface Message {
    id: string;
    text: string;
    senderId: 'user' | 'theo';
    timestamp: Date;
    custom?: {
        pack?: string;
        rawText?: string;
        unlock?: string;
    };
}

interface ChatResponse {
    text: string;
    custom: {
        pack?: string;
        rawText?: string;
        unlock?: string;
    };
}

interface CustomChatProps {
    onOpenModal: ({ pack, packId }: { pack?: Pack; packId?: string }) => void;
    onOpenSellModal?: (salePack: SalePack) => void;
    partner: string;
    conversationId: string;
}

export const STAR_ATLAS_CONVERSATION_STARTERS = [
    "Hello! I'm THEO, I can assist you in starting Star Atlas, explaining game mechanics, and providing item packs for your adventures.\n\nI can communicate in any major language, not just English.\n\nI am constantly improving and learning, so please feel free to ask me anything you would like to do. What would you like to know or do?",
    `Hello, Space Traveler! I'm THEO, here to support you in your Star Atlas exploration.\n\nWhether you have questions about the game's lore, mechanics, or want recommendations for item packs, just ask!\n\nI can assist you in any major language, not only English.\n\nI am constantly improving and learning, so please feel free to ask me anything you would like to do. What would you like to know or do?`,
    `Greetings, Adventurer! I'm THEO, your guide to the Star Atlas universe.\n\nI can help you with game mechanics, item packs, and more. I'm also able to communicate in all major languages, not just English.\n\nI am constantly improving and learning, so please feel free to ask me anything you would like to do. What would you like to know or do?`,
    `Greetings, Explorer! I'm THEO, your guide to the universe of Star Atlas.\n\nWhether you need help getting started, understanding the mechanics, or acquiring item packs, I'm here to assist. And remember, I can communicate in any major language, not just English.\n\nI am constantly improving and learning, so please feel free to ask me anything you would like to do. What would you like to know or do?`,
];

interface SellableItem {
    name: string;
    price: string;
    currency: string;
    quantityInWallet: number;
    buyOrderQuantity: number;
    maxPossibleSale: number;
    totalValue: string;
}

interface ItemSelectionState {
    [key: string]: number | null;
}

const ItemSelectionDropdown: React.FC<{
    items: SellableItem[];
    onSelectionChange: (selections: ItemSelectionState) => void;
}> = ({ items, onSelectionChange }) => {
    const [selectedItems, setSelectedItems] = useState<ItemSelectionState>({});
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleItem = (item: SellableItem) => {
        setSelectedItems(prev => {
            const newState = { ...prev };
            if (newState[item.name]) {
                delete newState[item.name];
            } else {
                newState[item.name] = item.maxPossibleSale;
            }
            return newState;
        });
    };

    const handleQuantityChange = (itemName: string, value: string) => {
        setSelectedItems(prev => {
            const newState = { ...prev };
            if (value === '') {
                newState[itemName] = null;
            } else {
                const quantity = parseInt(value);
                if (quantity <= 0) {
                    delete newState[itemName];
                } else {
                    newState[itemName] = quantity;
                }
            }
            return newState;
        });
    };

    const handleSend = () => {
        const validSelections = Object.entries(selectedItems)
            .filter(entry => {
                const quantity = entry[1];
                return typeof quantity === 'number' && quantity > 0;
            })
            .reduce((acc, [name, quantity]) => ({
                ...acc,
                [name]: quantity
            }), {});

        if (Object.keys(validSelections).length > 0) {
            onSelectionChange(validSelections);
            setIsOpen(false);
        }
    };

    const hasValidSelections = Object.keys(selectedItems).length > 0;

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className="px-6 py-2 bg-[#292930] text-white hover:bg-[#32323a] rounded-md transition-colors duration-200"
            >
                SELECT ITEMS TO SELL ({items.length})
            </button>

            {isOpen && (
                <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" onClick={() => setIsOpen(false)} />

                        <div className="inline-block align-bottom bg-[#1d1d24] rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                            <div className="bg-[#1d1d24] px-4 pt-5 pb-4 sm:p-6">
                                <div className="flex justify-between items-center mb-4">
                                    <div>
                                        <h3 className="text-lg font-medium text-white">Select Items to Sell</h3>
                                        <p className="text-sm text-gray-400 mt-1">Click on items to select them for sale. Adjust quantities as needed.</p>
                                    </div>
                                    <button onClick={() => setIsOpen(false)} className="text-gray-400 hover:text-white">
                                        ✕
                                    </button>
                                </div>

                                <div className="mt-4 max-h-[60vh] overflow-y-auto">
                                    {items.map((item) => (
                                        <div
                                            key={item.name}
                                            className={`p-4 mb-2 rounded-lg border ${selectedItems[item.name] ? 'border-[#ffbe4d] bg-[#32323a]' : 'border-[#32323a] bg-[#292930]'
                                                } hover:border-[#ffbe4d] transition-colors duration-200`}
                                        >
                                            <div className="flex justify-between items-center">
                                                <div className="flex-1">
                                                    <div
                                                        className="flex items-center gap-2 cursor-pointer"
                                                        onClick={() => handleToggleItem(item)}
                                                    >
                                                        <div className={`w-5 h-5 flex items-center justify-center border rounded ${selectedItems[item.name] ? 'border-[#ffbe4d] bg-[#ffbe4d]' : 'border-gray-400'
                                                            }`}>
                                                            {selectedItems[item.name] && (
                                                                <span className="text-black">✓</span>
                                                            )}
                                                        </div>
                                                        <span className="font-medium text-white">{item.name}</span>
                                                    </div>
                                                    <div className="text-sm text-gray-400 ml-7">
                                                        Price: {item.price}
                                                    </div>
                                                    <div className="text-sm text-gray-400 ml-7">
                                                        Available: {Math.min(item.quantityInWallet, item.buyOrderQuantity)}
                                                    </div>
                                                </div>
                                                {selectedItems[item.name] !== undefined && (
                                                    <div className="flex items-center space-x-2">
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            max={Math.min(item.quantityInWallet, item.buyOrderQuantity)}
                                                            value={selectedItems[item.name] ?? ''}
                                                            onChange={(e) => handleQuantityChange(item.name, e.target.value)}
                                                            className="w-20 px-2 py-1 bg-[#1d1d24] text-white rounded-md border border-[#32323a]"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="bg-[#292930] px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={handleSend}
                                    disabled={!hasValidSelections}
                                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm ${hasValidSelections
                                        ? 'bg-[#ffbe4d] text-black hover:bg-[#ffc107]'
                                        : 'bg-gray-600 text-gray-300 cursor-not-allowed'
                                        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ffbe4d]`}
                                >
                                    Send Selection
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setIsOpen(false)}
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-[#32323a] shadow-sm px-4 py-2 bg-[#1d1d24] text-base font-medium text-gray-400 hover:text-white hover:bg-[#32323a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#32323a] sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export const QUICK_ACTIONS = [
    "How to get started?",
    "I want to sell my items",
    "I want to buy a pack"
];

export const SELL_ACTIONS = [
    "SELL ALL MY ITEMS"
];

export function chooseElement(choices: string[]) {
    var index = Math.floor(Math.random() * choices.length);
    return choices[index];
}

interface ActionButtonProps {
    pack?: Pack;
    salePack?: SalePack;
    onBuy?: ({ pack }: { pack: Pack }) => void;
    onSell?: (salePack: SalePack) => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ pack, salePack, onBuy, onSell }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async () => {
        setIsLoading(true);
        try {
            if (pack && onBuy) {
                onBuy({ pack });
            } else if (salePack && onSell) {
                onSell(salePack);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const isSellAction = Boolean(salePack);

    return (
        <div className="flex justify-start mt-2">
            <button
                onClick={handleClick}
                disabled={isLoading}
                className="px-6 py-2 max-w-[200px] bg-[#ffbe4d] hover:bg-[#ffc107] text-[#1d1d24] font-medium rounded-md transition-colors duration-200 disabled:opacity-50"
            >
                {isLoading ? (isSellAction ? 'Selling...' : 'Buying...') : (isSellAction ? 'Sell Now' : 'Buy Now')}
            </button>
        </div>
    );
};

const CustomChat: React.FC<CustomChatProps> = ({ onOpenModal, onOpenSellModal, partner, conversationId }) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const { connected, publicKey } = useWallet();
    const { chain } = useChain();
    const chainFns = useNeoChain() as any;
    const { signIn } = chainFns?.[chain?.name?.toLowerCase() || "not_found"];
    const solanaWalletModal = useWalletModal();
    const { updateState } = useAppContext();
    const [currentPackData, setCurrentPackData] = useState<Pack | undefined>(undefined);
    const [lastWalletRequestMessage, setLastWalletRequestMessage] = useState<Message | null>(null);
    const { gaConnectWalletClick } = useUA();
    const sentAddressesRef = useRef(new Set<string>());
    const isConnectingRef = useRef(false);
    const unsubscribeRef = useRef<() => void>();
    const lastMessageRef = useRef<string>('');

    // Initialize Firestore listener
    useEffect(() => {
        if (!partner || !conversationId) return;

        const db = Firebase.getDBApp();
        const messagesRef = collection(db, 'clients', partner, 'chatRooms', conversationId, 'messages');
        const q = query(messagesRef, orderBy('timestamp', 'asc'));

        // Get the initial welcome message
        const welcomeMessage: Message = {
            id: generateRandomString(10),
            text: partner === 'starAtlas'
                ? chooseElement(STAR_ATLAS_CONVERSATION_STARTERS)
                : "Hello! I can help you to find the right pack for you. Tell me, what are you looking for?",
            senderId: 'theo',
            timestamp: new Date()
        };

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newMessages: Message[] = [];
            snapshot.forEach((doc) => {
                const data = doc.data();
                const timestamp = data.timestamp instanceof Timestamp ?
                    data.timestamp.toDate() :
                    new Date();

                newMessages.push({
                    id: doc.id,
                    text: data.content || data.text,
                    senderId: data.senderId || 'theo',
                    timestamp,
                    custom: data.custom || {}
                });
            });

            // Always include welcome message at the start
            if (newMessages.length === 0) {
                setMessages([welcomeMessage]);
            } else {
                setMessages([welcomeMessage, ...newMessages]);
            }
        }, (error) => {
            console.error("Error listening to messages:", error);
            toast.error('Failed to load chat messages');
            // On error, at least show the welcome message
            setMessages([welcomeMessage]);
        });

        unsubscribeRef.current = unsubscribe;
        return () => unsubscribe();
    }, [partner, conversationId]);

    // Handle wallet connection
    const handleWalletConnect = () => {
        if (isConnectingRef.current) return;
        isConnectingRef.current = true;
        gaConnectWalletClick();
        signIn();
    };

    // Handle successful connection and send wallet address
    useEffect(() => {
        console.log("Connection effect triggered:", { connected, publicKey: publicKey?.toString(), lastWalletRequestMessage });

        if (!connected || !publicKey || !lastWalletRequestMessage) {
            console.log("Early return - missing required data");
            isConnectingRef.current = false;
            return;
        }

        // Check if we've already sent an address for this request
        const requestId = lastWalletRequestMessage.id;
        console.log("Checking request:", { requestId, alreadySent: sentAddressesRef.current.has(requestId) });

        if (sentAddressesRef.current.has(requestId)) {
            console.log("Already sent address for this request");
            isConnectingRef.current = false;
            return;
        }

        // Mark this request as handled immediately
        console.log("Marking request as handled:", requestId);
        sentAddressesRef.current.add(requestId);

        const walletAddress = publicKey.toString();
        console.log("SENDING WALLET ADDRESS:", walletAddress);
        const userMessage: Message = {
            id: generateRandomString(10),
            text: `Here is my wallet address: ${walletAddress}`,
            senderId: 'user',
            timestamp: new Date()
        };

        sendMessage(userMessage.text);
        setLastWalletRequestMessage(null);
        isConnectingRef.current = false;
        console.log("Message sent and states reset");
    }, [connected, publicKey, lastWalletRequestMessage]);

    // Reset connection state when disconnected
    useEffect(() => {
        if (!connected) {
            isConnectingRef.current = false;
            setLastWalletRequestMessage(null);
        }
    }, [connected]);

    // Watch for new messages to check for wallet requests
    useEffect(() => {
        const lastMessage = messages[messages.length - 1];
        if (!lastMessage || lastMessage.senderId !== 'theo') return;

        const isWalletRequest = lastMessage.text.toLowerCase().includes('connect your wallet') ||
            lastMessage.text.toLowerCase().includes('provide your') ||
            lastMessage.text.toLowerCase().includes('please provide') ||
            lastMessage.text.toLowerCase().includes('connect wallet') ||
            lastMessage.text.toLowerCase().includes('connect a wallet');

        if (isWalletRequest && !connected && !sentAddressesRef.current.has(lastMessage.id)) {
            setLastWalletRequestMessage(lastMessage);
        }
    }, [messages, connected]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    // Add new useEffect to handle wallet connection errors
    useEffect(() => {
        const handleWalletError = (error: any) => {
            if (error?.message?.includes('User rejected')) {
                toast('You cancelled the wallet connection request', {
                    duration: 5000,
                });
            } else {
                toast.error(error?.message || 'Failed to connect wallet. Please try again.', {
                    duration: 5000,
                });
            }
        };

        window.addEventListener('unhandledrejection', (event) => {
            if (event.reason?.message?.includes('User rejected')) {
                event.preventDefault();
                handleWalletError(event.reason);
            }
        });

        return () => {
            window.removeEventListener('unhandledrejection', () => { });
        };
    }, []);

    const sendMessage = async (messageText: string) => {
        if (!messageText.trim() || isLoading) return;

        // Prevent duplicate messages
        if (messageText === lastMessageRef.current) {
            return;
        }
        lastMessageRef.current = messageText;

        // Create and show user message immediately
        const userMessage: Message = {
            id: generateRandomString(10),
            text: messageText,
            senderId: 'user',
            timestamp: new Date()
        };
        setMessages(prev => [...prev, userMessage]);
        setInputMessage('');

        setIsLoading(true);

        try {
            // Make API call to THEO
            const response = await fetch(process.env.REACT_APP_CUSTOM_CHAT_API_URL || '', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: {
                        conversationId,
                        partner: partner,
                        text: messageText,
                        messages: messages.map(msg => ({
                            id: msg.id,
                            text: msg.text,
                            senderId: msg.senderId === 'user' ? 'user' : 'theo',
                            custom: msg.custom || {},
                            timestamp: msg.timestamp
                        }))
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Failed to get response from THEO');
            }

            const data: ChatResponse = await response.json();
            handleCustomResponse(data);

        } catch (error) {
            console.error('Error in chat:', error);
            toast.error('Failed to send message');
        } finally {
            setIsLoading(false);
            lastMessageRef.current = '';
        }
    };

    const handleSendMessage = () => {
        sendMessage(inputMessage);
    };

    const handleSellAll = () => {
        setInputMessage("Yes please, prepare one sale pack with all the items.");
        sendMessage("Yes please, prepare one sale pack with all the items.");
    };

    const handleItemSelection = (selections: ItemSelectionState) => {
        if (Object.keys(selections).length === 0) return;

        const items = Object.entries(selections)
            .filter(([_, quantity]) => quantity !== null && quantity > 0);

        const selectionMessage = "Prepare a sale pack with " +
            items
                .map(([itemName, quantity], index) => {
                    if (items.length === 1) return `${quantity} of ${itemName}`;
                    if (index === items.length - 1) return `and ${quantity} of ${itemName}`;
                    return `${quantity} of ${itemName}`;
                })
                .join(items.length > 2 ? ', ' : ' ');

        setInputMessage(selectionMessage);
        sendMessage(selectionMessage);
    };

    const getSellableItems = (): SellableItem[] => {
        const lastMessage = messages[messages.length - 1];
        if (!lastMessage?.custom?.rawText) {
            console.log('No rawText in last message');
            return [];
        }

        try {
            // Parse the raw text into JSON
            const responseData = JSON.parse(lastMessage.custom.rawText);
            console.log('Parsed response data:', responseData);

            // Find the tool response
            const toolResponse = responseData.find((item: any) => item.role === 'tool');
            if (!toolResponse?.content) {
                console.log('No tool response found in:', responseData);
                return [];
            }

            // Parse the tool response content
            const toolContent = JSON.parse(toolResponse.content);
            console.log('Parsed tool content:', toolContent);

            // Parse the context string which contains the actual data
            const contextData = JSON.parse(toolContent.context);
            console.log('Parsed context data:', contextData);

            if (!contextData.itemsWithOrders || !Array.isArray(contextData.itemsWithOrders)) {
                console.log('No itemsWithOrders array found in context:', contextData);
                return [];
            }

            // Map the items to our interface
            const items = contextData.itemsWithOrders.map((item: any) => ({
                name: item.itemName,
                price: item.price,
                currency: item.currency,
                quantityInWallet: item.quantityInWallet,
                buyOrderQuantity: item.buyOrderQuantity,
                maxPossibleSale: item.possibleSaleQuantity,
                totalValue: item.totalPossibleSale
            }));
            console.log('Mapped items:', items);
            return items;
        } catch (error) {
            console.error('Error parsing sellable items:', error);
            return [];
        }
    };

    const handleQuickAction = (message: string) => {
        if (message === "SELL ALL MY ITEMS") {
            handleSellAll();
        } else if (message === "I want to sell my items" && connected && publicKey) {
            const messageWithWallet = `${message}\nHere is my wallet address: ${publicKey.toString()}`;
            setInputMessage(messageWithWallet);
            sendMessage(messageWithWallet);
        } else {
            setInputMessage(message);
            sendMessage(message);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleConnect = () => {
        solanaWalletModal.setVisible(true);
    };

    const renderMessageContent = (message: Message) => {
        let packData: Pack | undefined;
        let salePackData: SalePack | undefined;

        // Check if message is asking for wallet connection
        const isWalletRequest = message.text.toLowerCase().includes('connect your wallet') ||
            message.text.toLowerCase().includes('provide your') ||
            message.text.toLowerCase().includes('please provide') ||
            message.text.toLowerCase().includes('connect wallet') ||
            message.text.toLowerCase().includes('connect a wallet') ||
            message.text.toLowerCase().includes('solana wallet');

        if (isWalletRequest && !connected) {
            return (
                <div>
                    <ReactMarkdown
                        className="prose prose-sm max-w-none prose-invert"
                        components={{
                            p: ({ children, ...props }) => (
                                <p {...props} className="whitespace-pre-line">
                                    {children}
                                </p>
                            )
                        }}
                    >
                        {message.text}
                    </ReactMarkdown>
                    <div className="mt-4">
                        <button
                            onClick={handleWalletConnect}
                            className="px-6 py-2 text-base bg-primary hover:bg-primary-hover text-white rounded-lg transition-all duration-200 disabled:opacity-50 font-medium hover:shadow-md hover:scale-[1.02]"
                        >
                            Connect Wallet
                        </button>
                    </div>
                </div>
            );
        }

        if (message.custom?.pack) {
            try {
                const parsedData = JSON.parse(message.custom.pack);
                // Check if it's a sale pack by looking for walletAddress property
                if ('walletAddress' in parsedData) {
                    salePackData = parsedData;
                } else {
                    packData = parsedData;
                }
            } catch (error) {
                console.error('Error parsing pack data:', error);
            }
        }

        return (
            <div>
                <ReactMarkdown
                    className="prose prose-sm max-w-none prose-invert"
                    components={{
                        p: ({ children, ...props }) => (
                            <p {...props} className="whitespace-pre-line">
                                {children}
                            </p>
                        )
                    }}
                >
                    {message.text}
                </ReactMarkdown>
                {(packData || salePackData) && (
                    <ActionButton
                        pack={packData}
                        salePack={salePackData}
                        onBuy={onOpenModal}
                        onSell={onOpenSellModal}
                    />
                )}
            </div>
        );
    };

    const handleBuyClick = (pack: Pack) => {
        if (onOpenModal) {
            onOpenModal({ pack });
        }
    };

    const handleSellClick = (salePack: SalePack) => {
        if (onOpenSellModal) {
            onOpenSellModal(salePack);
        }
    };

    const handleCustomResponse = (response: any) => {
        if (response.custom?.pack) {
            const packData = JSON.parse(response.custom.pack);
            // Don't automatically trigger modals
            // Store the data for the action button to use
            setCurrentPackData(packData);
        }
    };

    const isWalletItemsResponse = (message: Message): boolean => {
        try {
            if (!message.custom?.rawText) return false;

            const data = JSON.parse(message.custom.rawText);
            // Look for getWalletSales function call in the response
            return data.some((item: any) =>
                item.tool_calls?.[0]?.function?.name === "getWalletSales" ||  // Check in tool_calls
                item.tool?.content?.includes("getWalletSales")                // Check in tool content
            );
        } catch (error) {
            console.error('Error parsing message data:', error);
            return false;
        }
    };

    const renderSellActions = () => {
        const lastMessage = messages[messages.length - 1];
        if (!lastMessage || !isWalletItemsResponse(lastMessage)) return null;

        return (
            <div className="flex items-center gap-2 mt-4">
                {SELL_ACTIONS.map((action) => (
                    <button
                        key={action}
                        onClick={() => handleQuickAction(action)}
                        disabled={isLoading}
                        className="px-4 py-2 text-sm font-medium text-white bg-[#292930] hover:bg-[#32323a] focus:outline-none focus:ring-2 focus:ring-[#ffbe4d] disabled:opacity-50 disabled:cursor-not-allowed uppercase"
                    >
                        {action}
                    </button>
                ))}
                <ItemSelectionDropdown
                    items={getSellableItems()}
                    onSelectionChange={handleItemSelection}
                />
            </div>
        );
    };

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTo({
                top: chatContainerRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, isLoading]);

    return (
        <div className="flex flex-col h-[600px] bg-[#1d1d24] rounded-lg shadow-lg">
            <div
                className="flex-1 overflow-y-auto p-4 space-y-4 scroll-smooth"
                ref={chatContainerRef}
            >
                {messages.map((message, index) => (
                    <div key={message.id}>
                        <div className={`flex ${message.senderId === 'user' ? 'justify-end' : 'justify-start'}`}>
                            <div className={`max-w-[80%] rounded-lg p-3 ${message.senderId === 'user'
                                ? 'bg-[#203444] text-white'
                                : 'bg-[#292930] text-white'}`}
                                style={{ marginLeft: message.senderId === 'user' ? 'auto' : '0', marginRight: message.senderId === 'user' ? '0' : 'auto' }}
                            >
                                {renderMessageContent(message)}
                            </div>
                        </div>
                        {isWalletItemsResponse(message) && renderSellActions()}
                    </div>
                ))}
                {messages.length === 1 && partner === 'starAtlas' && (
                    <div className="flex flex-wrap gap-2 mt-4">
                        {/* {QUICK_ACTIONS.map((action) => (
                            <button
                                key={action}
                                onClick={() => handleQuickAction(action)}
                                disabled={isLoading}
                                className="px-4 py-2 text-sm font-medium text-white bg-[#292930] hover:bg-[#32323a] focus:outline-none focus:ring-2 focus:ring-[#ffbe4d] disabled:opacity-50 disabled:cursor-not-allowed uppercase"
                            >
                                {action}
                            </button>
                        ))} */}
                    </div>
                )}
                {isLoading && (
                    <div className="flex justify-start">
                        <div className="bg-[#292930] rounded-lg p-3">
                            <ThinkingIndicator />
                        </div>
                    </div>
                )}
            </div>

            <div className="border-t border-[#32323a] p-4">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSendMessage();
                    }}
                    className="flex space-x-2"
                >
                    <input
                        ref={inputRef}
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Send a message..."
                        disabled={isLoading}
                        className="flex-1 px-4 py-2 bg-[#292930] text-white border border-[#32323a] rounded-md focus:outline-none focus:ring-2 focus:ring-[#ffbe4d] focus:border-[#ffbe4d] disabled:opacity-50 disabled:bg-[#1e1e1e] placeholder-gray-500"
                    />
                    <button
                        type="submit"
                        disabled={!inputMessage.trim() || isLoading}
                        className="inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md text-[#1d1d24] bg-[#ffbe4d] hover:bg-[#ffc107] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ffc107] disabled:opacity-50 disabled:cursor-not-allowed"
                        aria-label="Send message"
                    >
                        <IoSend className="h-5 w-5" />
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CustomChat; 